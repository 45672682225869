/* plan-pricing-showcase
----------------------------------------------------------------------------------------*/

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

.plan-pricing-showcase {
  position: relative;
  padding-bottom: 100px;
}

.plan-pricing-showcase .title {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 0.42px;
  text-align: center;
  color: #200a8f;
  margin-left: auto;
  margin-right: auto;
  padding-top: 59px;
}

.plan-pricing-showcase .sub-title {
  font-size: 24px;
  text-align: center;
  color: #2f2f2f;
  padding-top: 8px;
  padding-bottom: 34px;
}

.learn-more {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 0px;
}

.switch-btn {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.vingt-off {
  display: inline-flex;
  padding-top: 10px;
  padding-left: 52%;
  padding-bottom: 31px;
}

.icon-arrow {
  transform: rotate(90deg);
  width: 45.1px;
  height: 39px;
  position: absolute;
  margin-left: 4.5%;
  margin-top: -1.9%;
}

.save-purcent {
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: normal;
  color: #2f2f2f;
}

.flowers-img {
  position: absolute;
  margin-top: -98px;
  margin-left: 8px;
}

@media screen and (max-width: 1185px) {
  .icon-arrow {
    margin-left: 7.5%;
    margin-top: -2.9%;
  }
}

@media screen and (max-width: 590px) {
  .flowers-img {
    display: none;
  }

  .icon-arrow {
    margin-left: 12.5%;
    margin-top: -4.9%;
  }
}

#calculator-img {
  position: absolute;
  bottom: 24px;
  right: -35px;
}
