/* pricing-box
----------------------------------------------------------------------------------------*/
.plan-pricing-showcase {
  position: relative;
  padding-bottom: 70px;
}

.plan-pricing-showcase .content-pricing-box {
  width: 300px;
  display: inline-block;
  text-align: left;
  position: relative;
  padding: 24px 20px 84px 20px;
  border-radius: 28px;
  border: solid 1px #d2d2d2;
  background-color: #fff;
  box-sizing: border-box;
  height: 840px;
}

.plan-pricing-showcase .content-pricing-box.recommended {
  border: solid 1px #200a8f;
}
.plan-pricing-showcase .content-pricing-box:not(.recommended) .tag-recommended {
  display: none;
}

.plan-pricing-showcase .content-pricing-box .tag-recommended {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  top: -12px;
  left: 0;
  right: 0;
}
.plan-pricing-showcase .content-pricing-box .tag-recommended div {
  padding: 1px 8px;
  border-radius: 3px;
  background-color: #200a8f;
  display: inline-block;
}

.plan-pricing-showcase .content-text .title {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.55px;
  text-align: center;
  color: #171719;
  padding-top: 0px;
}

.plan-pricing-showcase .content-text .subtitle {
  height: 23px;
  font-size: 13px;
  line-height: 1.77;
  text-align: center;
  color: #7f7f7f;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 14px;
}

.plan-pricing-showcase .content-text .price {
  text-align: center;
  color: #200a8f;
  height: 34px;
}

.plan-pricing-showcase .content-text .price.customPlan {
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: center;
  color: #0e004b;
  font-size: 24px;
  font-weight: 700;
}

.plan-pricing-showcase .content-text .price .maj {
  width: 20px;
  height: 34px;
  margin: 0 0 0 2px;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0.28px;
  text-align: center;
}

.plan-pricing-showcase .content-text .price .min {
  width: 77px;
  height: 34px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.18px;
  text-align: center;
}

.plan-pricing-showcase .content-text .sub-price {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.77;
  text-align: center;
  color: #7f7f7f;
  padding-bottom: 24px;
}

.plan-pricing-showcase .content-text .sub-price.only-text {
  padding-top: 16px;
  line-height: 1.31;
  letter-spacing: -0.1px;
  padding-bottom: 30px;
}

.plan-pricing-showcase .content-text .content-advantages .title {
  font-size: 13px;
  font-weight: bold;
  line-height: 1.85;
  color: #000;
  text-align: left;
  padding-bottom: 8px;
  margin-bottom: 0px;
}

.plan-pricing-showcase .content-text .content-advantages .raw-advantage {
  display: flex;
  align-items: center;
}

.plan-pricing-showcase .content-text .content-advantages .advantages-box {
  box-sizing: border-box;
  min-height: 120px;
}

.plan-pricing-showcase
  .content-text
  .content-advantages
  .advantages-box:first-child {
  height: 220px;
}

.plan-pricing-showcase
  .content-text
  .content-advantages
  .advantages-box
  .advantage-lst {
  color: #7f7f7f;
  font-feature-settings: "liga" off, "clig" off;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
  margin-left: 8px;
}

.btn-position {
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  text-align: center;
}

.button.backgroundless.contact-sales {
  width: 130px;
}

.button.light-blue {
  background-color: #e2ebfd;
  color: #3a79e6;
}

.button.backgroundless {
  background-color: transparent !important;
  color: #3a79e6;
  border-radius: 17px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}

.button.backgroundless.font-weight-600 {
  font-weight: 600;
}

@media screen and (max-width: 1290px) {
  .plan-pricing-showcase .content-pricing-box {
    width: 100%;
    display: block;
  }
}

@media screen and (max-width: 1185px) {
  .column-percent-25 {
    width: 50%;
  }

  .plan-pricing-showcase .content-pricing-box {
    width: 90%;
    margin-left: 20px;
    margin-bottom: 10px;
  }

  /* AVANTAGES LST */
  .plan-pricing-showcase .content-text .content-advantages .title {
    text-align: center;
  }
  .plan-pricing-showcase .content-text .content-advantages {
    width: 215px;
    margin-left: auto;
    margin-right: auto;
  }

  /* BTN POSITION */
  .btn-position {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    text-align: center;
  }
}

@media screen and (max-width: 590px) {
  .plan-pricing-showcase
    .content-pricing-box
    .content-advantages
    .advantages-box:first-child {
    height: auto;
  }

  .plan-pricing-showcase
    .content-pricing-box
    .content-advantages
    .advantages-box:last-child {
    height: auto;
  }

  .plan-pricing-showcase .content-pricing-box {
    height: auto;
  }

  .column-percent-25 {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 30px;
  }

  .btn-position {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    text-align: center;
  }
}

.sub-price-free {
  padding: 0 0 24px 0 !important;
}

@media screen and (max-width: 430px) {
  .column-percent-25 {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 30px;
  }

  .content-advantages {
    text-align: center;
  }

  .btn-position {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    text-align: center;
  }
}
