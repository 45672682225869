/* animation-fade-in
----------------------------------------------------------------------------------------*/

@-webkit-keyframes animation-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes animation-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.animation-fade-in {
    opacity: 1;
    animation: animation-fade-in 0.3s ease;
    -moz-animation: animation-fade-in 0.3s ease;
    -webkit-animation: animation-fade-in 0.3s ease;
    -ms-animation: animation-fade-in 0.3s ease;
    -o-animation: animation-fade-in 0.3s ease;
}

.animation-name-fade-in {
    animation-name: animation-fade-in;
    -webkit-animation-name: animation-fade-in;
}

/* animation-fade-out
----------------------------------------------------------------------------------------*/

@-webkit-keyframes animation-fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes animation-fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.animation-fade-out {
    opacity: 0;
    animation: animation-fade-out 0.3s ease;
    -moz-animation: animation-fade-out 0.3s ease;
    -webkit-animation: animation-fade-out 0.3s ease;
    -ms-animation: animation-fade-out 0.3s ease;
    -o-animation: animation-fade-out 0.3s ease;
}

.animation-name-fade-out {
    animation-name: animation-fade-out;
    -webkit-animation-name: animation-fade-out;
}

/* animation-fade-in-down
----------------------------------------------------------------------------------------*/

@-webkit-keyframes animation-fade-in-down {
    0% {
        -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes animation-fade-in-down {
    0% {
        -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

.animation-fade-in-down {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    animation: animation-fade-in-down 0.3s ease;
    -moz-animation: animation-fade-in-down 0.3s ease;
    -webkit-animation: animation-fade-in-down 0.3s ease;
    -ms-animation: animation-fade-in-down 0.3s ease;
    -o-animation: animation-fade-in-down 0.3s ease;
}

.animation-name-fade-in-down {
    animation-name: animation-fade-in-down;
    -webkit-animation-name: animation-fade-in-down;
}

/* animation-fade-out-up
----------------------------------------------------------------------------------------*/

@-webkit-keyframes animation-fade-out-up {
    0% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        transform: translateY(-100%);
        opacity: 0;
    }
}


@keyframes animation-fade-out-up {
    0% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        transform: translateY(-100%);
        opacity: 0;
    }
}

.animation-fade-out-up {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    animation: animation-fade-out-up 0.3s ease;
    -moz-animation: animation-fade-out-up 0.3s ease;
    -webkit-animation: animation-fade-out-up 0.3s ease;
    -ms-animation: animation-fade-out-up 0.3s ease;
    -o-animation: animation-fade-out-up 0.3s ease;
}

.animation-name-fade-out-up {
    animation-name: animation-fade-out-up;
    -webkit-animation-name: animation-fade-out-up;
}

/* animation-slide-in
----------------------------------------------------------------------------------------*/

@-webkit-keyframes animation-slide-in {
    0% {
        -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    100% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes animation-slide-in {
    0% {
        -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    100% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

.animation-slide-in {
    animation: animation-slide-in 0.3s ease;
    -moz-animation: animation-slide-in 0.3s ease;
    -webkit-animation: animation-slide-in 0.3s ease;
    -ms-animation: animation-slide-in 0.3s ease;
    -o-animation: animation-slide-in 0.3s ease;
}

.animation-name-slide-in {
    animation-name: animation-slide-in;
    -webkit-animation-name: animation-slide-in;
}

/* animation-slide-out
----------------------------------------------------------------------------------------*/

@-webkit-keyframes animation-slide-out {
    0% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        transform: translateY(-100%);
    }
}

@keyframes animation-slide-out {
    0% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        transform: translateY(-100%);
    }
}

.animation-slide-out {
    animation: animation-slide-out 0.3s ease;
    -moz-animation: animation-slide-out 0.3s ease;
    -webkit-animation: animation-slide-out 0.3s ease;
    -ms-animation: animation-slide-out 0.3s ease;
    -o-animation: animation-slide-out 0.3s ease;
}

.animation-name-slide-out {
    animation-name: animation-slide-out;
    -webkit-animation-name: animation-slide-out;
}


/* animation-poof
----------------------------------------------------------------------------------------*/

@-webkit-keyframes animation-poof {
    0% {
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
    50% {
        opacity: 0.3;
        -webkit-transform: scale(1.3);
        -ms-transform: scale(1.3);
        transform: scale(1.3);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes animation-poof {
    0% {
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
    50% {
        opacity: 0.3;
        -webkit-transform: scale(1.3);
        -ms-transform: scale(1.3);
        transform: scale(1.3);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}

.animation-poof {
    opacity: 1;
    animation: animation-poof 0.3s ease;
    -moz-animation: animation-poof 0.3s ease;
    -webkit-animation: animation-poof 0.3s ease;
    -ms-animation: animation-poof 0.3s ease;
    -o-animation: animation-poof 0.3s ease;
}

.animation-name-poof {
    animation-name: animation-poof;
    -webkit-animation-name: animation-poof;
}

/* animation-shake
----------------------------------------------------------------------------------------*/

@-webkit-keyframes animation-shake {
    10%, 90% {
        -webkit-transform: translate3d(-1px, 0, 0);
        -ms-transform: translate3d(-1px, 0, 0);
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        -webkit-transform: translate3d(2px, 0, 0);
        -ms-transform: translate3d(2px, 0, 0);
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        -webkit-transform: translate3d(-4px, 0, 0);
        -ms-transform: translate3d(-4px, 0, 0);
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        -webkit-transform: translate3d(4px, 0, 0);
        -ms-transform: translate3d(4px, 0, 0);
        transform: translate3d(4px, 0, 0);
    }
}

@keyframes animation-shake {
    10%, 90% {
        -webkit-transform: translate3d(-1px, 0, 0);
        -ms-transform: translate3d(-1px, 0, 0);
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        -webkit-transform: translate3d(2px, 0, 0);
        -ms-transform: translate3d(2px, 0, 0);
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        -webkit-transform: translate3d(-4px, 0, 0);
        -ms-transform: translate3d(-4px, 0, 0);
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        -webkit-transform: translate3d(4px, 0, 0);
        -ms-transform: translate3d(4px, 0, 0);
        transform: translate3d(4px, 0, 0);
    }
}

.animation-shake {
    animation: animation-shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -moz-animation: animation-shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-animation: animation-shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -ms-animation: animation-shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -o-animation: animation-shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
}

.animation-name-shake {
    animation-name: animation-shake;
    -webkit-animation-name: animation-shake;
}

/* animation-blink
---------------------------------------------------------------------------------------------*/

@-webkit-keyframes animation-blink {
    0% {
        opacity: .2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: .2;
    }
}

@keyframes animation-blink {
    0% {
        opacity: .2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: .2;
    }
}

.animation-blink {
    animation: animation-blink 1.4s infinite both;
    -moz-animation: animation-blink 1.4s infinite both;
    -webkit-animation: animation-blink 1.4s infinite both;
    -ms-animation: animation-blink 1.4s infinite both;
    -o-animation: animation-blink 1.4s infinite both;
}

.animation-name-blink {
    animation-name: animation-blink;
    -webkit-animation-name: animation-blink;
}


/* animation rotate
----------------------------------------------------------------------------------------*/

@-webkit-keyframes animation-rotate {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes animation-rotate {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.animation-rotate {
    animation: animation-rotate 0.3s ease;
    -moz-animation: animation-rotate 0.3s ease;
    -webkit-animation: animation-rotate 0.3s ease;
    -ms-animation: animation-rotate 0.3s ease;
    -o-animation: animation-rotate 0.3s ease;
    animation-name: animation-rotate;
    -webkit-animation-name: animation-rotate;
}

/* animation dash
----------------------------------------------------------------------------------------*/

@-webkit-keyframes animation-dash {
    0% {
        stroke-dashoffset: 745.74853515625;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes animation-dash {
    0% {
        stroke-dashoffset: 745.74853515625;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

.animation-dash {
    animation: animation-dash 0.3s ease;
    -moz-animation: animation-dash 0.3s ease;
    -webkit-animation: animation-dash 0.3s ease;
    -ms-animation: animation-dash 0.3s ease;
    -o-animation: animation-dash 0.3s ease;
}

.animation-name-dash {
    animation-name: animation-dash;
    -webkit-animation-name: animation-dash;
}

/* Animation duration
----------------------------------------------------------------------------------------*/

.animation-duration-0 {
    animation-duration: 0s;
    -webkit-animation-duration: 0s;
}

.animation-duration-0-1 {
    animation-duration: 0.1s;
    -webkit-animation-duration: 0.1s;
}

.animation-duration-0-2 {
    animation-duration: 0.2s;
    -webkit-animation-duration: 0.2s;
}

.animation-duration-0-3 {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
}

.animation-duration-0-4 {
    animation-duration: 0.4s;
    -webkit-animation-duration: 0.4s;
}

.animation-duration-0-5 {
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
}

.animation-duration-0-6 {
    animation-duration: 0.6s;
    -webkit-animation-duration: 0.6s;
}

.animation-duration-0-7 {
    animation-duration: 0.7s;
    -webkit-animation-duration: 0.7s;
}

.animation-duration-0-8 {
    animation-duration: 0.8s;
    -webkit-animation-duration: 0.8s;
}

.animation-duration-0-9 {
    animation-duration: 0.9s;
    -webkit-animation-duration: 0.9s;
}

.animation-duration-1 {
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
}

.animation-duration-1-1 {
    animation-duration: 1.1s;
    -webkit-animation-duration: 1.1s;
}

.animation-duration-1-2 {
    animation-duration: 1.2s;
    -webkit-animation-duration: 1.2s;
}

.animation-duration-1-3 {
    animation-duration: 1.3s;
    -webkit-animation-duration: 1.3s;
}

.animation-duration-1-4 {
    animation-duration: 1.4s;
    -webkit-animation-duration: 1.4s;
}

.animation-duration-1-5 {
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
}

.animation-duration-1-6 {
    animation-duration: 1.6s;
    -webkit-animation-duration: 1.6s;
}

.animation-duration-1-7 {
    animation-duration: 1.7s;
    -webkit-animation-duration: 1.7s;
}

.animation-duration-1-8 {
    animation-duration: 1.8s;
    -webkit-animation-duration: 1.8s;
}

.animation-duration-1-9 {
    animation-duration: 1.9s;
    -webkit-animation-duration: 1.9s;
}

.animation-duration-2 {
    animation-duration: 2s;
    -webkit-animation-duration: 2s;
}

/* Animation delay
----------------------------------------------------------------------------------------*/

.animation-delay-0 {
    animation-delay: 0s;
    -webkit-animation-delay: 0s;
}

.animation-delay-0-1 {
    animation-delay: 0.1s;
    -webkit-animation-delay: 0.1s;
}

.animation-delay-0-2 {
    animation-delay: 0.2s;
    -webkit-animation-delay: 0.2s;
}

.animation-delay-0-3 {
    animation-delay: 0.3s;
    -webkit-animation-delay: 0.3s;
}

.animation-delay-0-4 {
    animation-delay: 0.4s;
    -webkit-animation-delay: 0.4s;
}

.animation-delay-0-5 {
    animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s;
}

.animation-delay-0-6 {
    animation-delay: 0.6s;
    -webkit-animation-delay: 0.6s;
}

.animation-delay-0-7 {
    animation-delay: 0.7s;
    -webkit-animation-delay: 0.7s;
}

.animation-delay-0-8 {
    animation-delay: 0.8s;
    -webkit-animation-delay: 0.8s;
}

.animation-delay-0-9 {
    animation-delay: 0.9s;
    -webkit-animation-delay: 0.9s;
}

.animation-delay-1 {
    animation-delay: 1s;
    -webkit-animation-delay: 1s;
}

.animation-delay-1-1 {
    animation-delay: 1.1s;
    -webkit-animation-delay: 1.1s;
}

.animation-delay-1-2 {
    animation-delay: 1.2s;
    -webkit-animation-delay: 1.2s;
}

.animation-delay-1-3 {
    animation-delay: 1.3s;
    -webkit-animation-delay: 1.3s;
}

.animation-delay-1-4 {
    animation-delay: 1.4s;
    -webkit-animation-delay: 1.4s;
}

.animation-delay-1-5 {
    animation-delay: 1.5s;
    -webkit-animation-delay: 1.5s;
}

.animation-delay-1-6 {
    animation-delay: 1.6s;
    -webkit-animation-delay: 1.6s;
}

.animation-delay-1-7 {
    animation-delay: 1.7s;
    -webkit-animation-delay: 1.7s;
}

.animation-delay-1-8 {
    animation-delay: 1.8s;
    -webkit-animation-delay: 1.8s;
}

.animation-delay-1-9 {
    animation-delay: 1.9s;
    -webkit-animation-delay: 1.9s;
}

.animation-delay-2 {
    animation-delay: 2s;
    -webkit-animation-delay: 2s;
}


/* animation-fill-mode
----------------------------------------------------------------------------------------*/

.animation-fill-mode-none {
    animation-fill-mode: none;
    -webkit-animation-fill-mode: none;
}
.animation-fill-mode-forwards {
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
}
.animation-fill-mode-backwards {
    animation-fill-mode: backwards;
    -webkit-animation-fill-mode: backwards;
}
.animation-fill-mode-both {
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
}

/* animation-iteration-count
----------------------------------------------------------------------------------------*/

.animation-iteration-count-infinite {
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}
.animation-iteration-count-0 {
    animation-iteration-count: 0;
    -webkit-animation-iteration-count: 0;
}
.animation-iteration-count-1 {
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
}
.animation-iteration-count-2 {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
}
.animation-iteration-count-3 {
    animation-iteration-count: 3;
    -webkit-animation-iteration-count: 3;
}
.animation-iteration-count-4 {
    animation-iteration-count: 4;
    -webkit-animation-iteration-count: 4;
}
.animation-iteration-count-5 {
    animation-iteration-count: 5;
    -webkit-animation-iteration-count: 5;
}
.animation-iteration-count-6 {
    animation-iteration-count: 6;
    -webkit-animation-iteration-count: 6;
}
.animation-iteration-count-7 {
    animation-iteration-count: 7;
    -webkit-animation-iteration-count: 7;
}
.animation-iteration-count-8 {
    animation-iteration-count: 8;
    -webkit-animation-iteration-count: 8;
}
.animation-iteration-count-9 {
    animation-iteration-count: 9;
    -webkit-animation-iteration-count: 9;
}
.animation-iteration-count-10 {
    animation-iteration-count: 10;
    -webkit-animation-iteration-count: 10;
}

/* animation-play-state
----------------------------------------------------------------------------------------*/

.animation-play-state-inherit {
    animation-play-state: inherit;
    -webkit-animation-play-state: inherit;
}
.animation-play-state-initial {
    animation-play-state: initial;
    -webkit-animation-play-state: initial;
}
.animation-play-state-unset {
    animation-play-state: unset;
    -webkit-animation-play-state: unset;
}
.animation-play-state-running {
    animation-play-state: running;
    -webkit-animation-play-state: running;
}
.animation-play-state-paused {
    animation-play-state: paused;
    -webkit-animation-play-state: paused;
}


/* animation-timing-function
----------------------------------------------------------------------------------------*/

.animation-timing-function-inherit {
    animation-timing-function: inherit;
    -webkit-animation-timing-function: inherit;
}
.animation-timing-function-initial {
    animation-timing-function: initial;
    -webkit-animation-timing-function: initial;
}
.animation-timing-function-unset {
    animation-timing-function: unset;
    -webkit-animation-timing-function: unset;
}
.animation-timing-function-ease {
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
}
.animation-timing-function-ease-in {
    animation-timing-function: ease-in;
    -webkit-animation-timing-function: ease-in;
}
.animation-timing-function-ease-out {
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out;
}
.animation-timing-function-ease-in-out {
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
}
.animation-timing-function-linear {
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
}
.animation-timing-function-step-start {
    animation-timing-function: step-start;
    -webkit-animation-timing-function: step-start;
}
.animation-timing-function-step-end {
    animation-timing-function: step-end;
    -webkit-animation-timing-function: step-end;
}


/* End of file
----------------------------------------------------------------------------------------*/