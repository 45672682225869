html {
  scroll-behavior: smooth;
}

#projectNewInsight table {
  display: block;
  overflow-x: auto;
  max-width: 100%;
}

#projectNewInsight .central-content {
  margin-bottom: 40px;
  position: static;
}
#projectNewInsight .central-content #main-content {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-top: 34px;
  line-height: 1.6;
}
#projectNewInsight .central-content #main-content #menu {
  width: 20%;
  position: sticky;
  top: 98px;
}
#projectNewInsight .central-content #main-content #menu ul li {
  padding: 12px 0;
  padding-left: 16px;
  border-radius: 6px 0 0 6px;
  cursor: pointer;
}
#projectNewInsight .central-content #main-content #menu ul li a {
  font-size: 18px;
  font-weight: normal;
  color: #424242;
  text-decoration: none;
}
#projectNewInsight .central-content #main-content #menu ul li:hover {
  background-color: #fafafa;
}
#projectNewInsight .central-content #main-content #menu ul li.active {
  background-color: #f5f5f5;
}
#projectNewInsight .central-content #main-content #menu ul li.active a {
  font-weight: 500;
}
#projectNewInsight .central-content #main-content #text {
  width: 80%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border-left: 1px solid #eee;
  padding-left: 30px;
  font-size: 15px;
  color: #212121;
}
#projectNewInsight .central-content #main-content #text a {
  font-weight: 500;
  text-decoration: none;
  /* Pour supprimer le soulignement par défaut */
  color: #3a7fe7;
  /* Couleur du lien */
  transition: border-bottom 0.3s;
  /* Transition pour ajouter le soulignement */
}
#projectNewInsight .central-content #main-content #text a:hover {
  border-bottom: 1px solid #3a7fe7;
  /* Ajouter le soulignement au survol */
}
#projectNewInsight .central-content #main-content #text h1 {
  font-size: 28px;
  font-weight: 600;
  color: #000;
  margin-bottom: 16px;
}
#projectNewInsight .central-content #main-content #text h2 {
  font-size: 22px;
  font-weight: 600;
  color: #000;
  margin-bottom: 14px;
}
#projectNewInsight .central-content #main-content #text h3 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin-bottom: 12px;
  margin-top: 12px;
}
#projectNewInsight .central-content #main-content #text h4 {
  font-size: 15px;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
  margin-top: 10px;
}
#projectNewInsight .central-content #main-content #text ul {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  padding-left: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
}
#projectNewInsight .central-content #main-content #text ul li {
  position: relative;
  padding-left: 5px;
  list-style-type: disc;
}
#projectNewInsight .central-content #main-content #text p {
  padding-bottom: 15px;
}
#projectNewInsight .central-content #main-content #text ol {
  counter-reset: orderedlist;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  padding-left: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
}
#projectNewInsight .central-content #main-content #text ol > li {
  position: relative;
  padding-left: 5px;
  list-style-type: inherit;
}
#projectNewInsight .central-content #main-content #text ol > li::before {
  counter-increment: orderedlist;
  content: counter(orderedlist);
  width: 15px;
  height: 15px;
  font-size: 10px;
  text-align: center;
  border: 1px solid black;
  border-radius: 100%;
  position: absolute;
  left: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#projectNewInsight .central-content #main-content #text ol ul {
  padding-left: 20px;
}
#projectNewInsight .central-content #main-content #text ol ul li {
  list-style-type: disc;
  position: relative;
  padding-left: 5px;
}

#projectNewInsight .central-content #main-content #text blockquote {
  margin: 0;
  padding: 10px;
  background-color: #f5f5f5;
  border-left: 3px solid #0077cc;
  /* Styles pour les tableaux */
}
#projectNewInsight .central-content #main-content #text table {
  margin-top: 15px;
  margin-bottom: 15px;
  border-collapse: collapse;
  width: fit-content;
}
#projectNewInsight .central-content #main-content #text table,
#projectNewInsight .central-content #main-content #text th,
#projectNewInsight .central-content #main-content #text td {
  border: 1px solid #343434;
}
#projectNewInsight .central-content #main-content #text th {
  background-color: #ededed;
}
#projectNewInsight .central-content #main-content #text th,
#projectNewInsight .central-content #main-content #text td {
  padding: 10px;
  text-align: left;
  /* Styles pour les notes de bas de page ou avertissements */
}
#projectNewInsight .central-content #main-content #text .note {
  font-style: italic;
  color: #888;
  /* Styles pour les éléments préformatés (balise pre) */
}
#projectNewInsight .central-content #main-content #text pre {
  background-color: #f5f5f5;
  padding: 10px;
  font-family: Menlo, Monaco, monospace;
  white-space: pre-wrap;
  /* Styles pour les éléments de liste (balise dl) */
}
#projectNewInsight .central-content #main-content #text dl {
  margin: 10px 0;
  /* Styles pour les définitions dans la liste (balise dt) */
}
#projectNewInsight .central-content #main-content #text dt {
  font-weight: bold;
  /* Styles pour les éléments de description dans la liste (balise dd) */
}
#projectNewInsight .central-content #main-content #text dd {
  margin-left: 20px;
  margin-bottom: 20px;
}
#projectNewInsight .central-content #main-content #text #lastUpdate {
  align-self: flex-end;
  font-size: 12px;
  color: #9e9e9e;
}
#projectNewInsight .central-content #main-content #text div {
  margin-bottom: 10px;
}
#projectNewInsight #fond {
  width: 100vw;
  height: calc(100vh - 60px);
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(14, 0, 75, 0.3019607843);
  z-index: 16;
}
#projectNewInsight .share-tooltip {
  padding: 26px;
  width: 562px;
  border-radius: 12px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  position: absolute;
  z-index: 17;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
}
#projectNewInsight .share-tooltip h3 {
  font-size: 24px;
  font-weight: bold;
  color: #424242;
}
#projectNewInsight .share-tooltip p {
  font-size: 16px;
  font-weight: 500;
  color: #757575;
  display: flex;
  align-items: center;
  -moz-column-gap: 8px;
  column-gap: 8px;
  margin-top: 26px;
  margin-bottom: 28px;
}
#projectNewInsight .share-tooltip a {
  text-decoration: none;
}
#projectNewInsight .share-tooltip .close {
  position: absolute;
  top: 18px;
  right: 18px;
}
#projectNewInsight .share-tooltip .actions {
  font-size: 15px;
  font-weight: 500;
  color: #6d9eee;
  display: flex;
  align-items: center;
  -moz-column-gap: 10px;
  column-gap: 10px;
}
#projectNewInsight .share-tooltip .btn-box {
  display: flex;
  justify-content: space-between;
}
#projectNewInsight .share-tooltip .btn-box span:hover {
  cursor: pointer;
}
#projectNewInsight .share-tooltip .btn-box .content {
  display: flex;
  align-items: center;
  -moz-column-gap: 28px;
  column-gap: 28px;
  width: 100%;
}
#projectNewInsight .share-tooltip .btn-box .preview {
  display: flex;
  align-items: center;
  -moz-column-gap: 10px;
  column-gap: 10px;
}
#projectNewInsight .share-tooltip .btn-box .button {
  justify-self: flex-end;
}

#long-menu
  .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters:hover {
  background-color: white;
  position: relative;
}
#long-menu
  .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters:hover::before {
  content: "";
  width: 35px;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.0392156863);
  position: absolute;
  right: 10.525px;
  z-index: 0;
  border-radius: 100%;
}

.MuiPaper-root {
  margin-top: 0px;
  box-shadow: none !important;
  border: none;
  background-color: transparent !important;
  position: relative;
  overflow: visible !important;
}
.MuiPaper-root .MuiMenuItem-root {
  padding-left: 6px;
  padding-right: 6px;
  position: relative;
  left: 2px;
  bottom: 10px;
  overflow: visible !important;
}
.MuiPaper-root .MuiMenuItem-root:hover {
  background-color: transparent;
  position: relative;
}
.MuiPaper-root .MuiMenuItem-root:hover::before {
  content: "";
  width: 35px;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.0392156863);
  position: absolute;
  right: 1.5px !important;
  z-index: 0;
  border-radius: 100%;
}

@media only screen and (max-width: 1024px) {
  #menu {
    display: none;
  }

  #text {
    width: 100% !important;
    border: none !important;
    padding: 0 !important;
  }
}

@media only screen and (max-width: 768px) {
  #insights-score {
    display: none;
  }
}
