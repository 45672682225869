/* Main Header
----------------------------------------------------------------------------------------*/

.main-header-showcase {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 88px;
    z-index: 10;
    background-color: #fff;
    color: #7f7f7f;
    box-sizing: border-box;
    border-bottom: 1px solid #ebebeb;
}

.fake-main-header-showcase {
    position: relative;
    height: 88px;
}

.main-header-showcase .website-logo {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    height: 30px;
}

.website-tiny-logo {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    height: 20px;
    width: 25px;
}

.main-header-showcase .website-title {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.7px;
    color: #fff;
    text-decoration: none;
}

.main-header-showcase .float-right>* {
    vertical-align: middle;
}

/* header-separator
----------------------------------------------------------------------------------------*/

.header-separator {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 20px;
    width: 1px;
    height: 29px;
    opacity: 0.25;
    background-color: #ffffff;
}

/* header menu
----------------------------------------------------------------------------------------*/

.main-header-showcase .menu {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    list-style-type: none;
}

.main-header-showcase .menu li {
    padding: 0 15px;
    display: inline-block;
    vertical-align: top;
    font-weight: 600;
}

.main-header-showcase .menu li.menu-li-link:last-child, .main-header-showcase .menu li.only-mobile, .main-header-showcase .menu li.menu-li-link.with-signin {
    padding-right: 0;
}

.main-header-showcase .menu>li>a {
    font-size: 15px;
    color: #7f7f7f;
    text-decoration: none;
}

.main-header-showcase .menu>li>a:not(.button):hover, .main-header-showcase .menu>li.active>a {
    color: #3a79e6;
}

.main-header-showcase .menu>li .button {
    color: #fff;
    border-radius: 17px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 30px;
}

.header-menu .displayed {
    position: relative;
    cursor: default;
    height: 60px;
}

@media all and (max-width: 600px) {
    .main-header-showcase .menu li {
        padding: 0 10px;
    }
    .main-header-showcase .menu li:last-child {
        padding-right: 0;
    }
    .main-header-showcase .menu>li .button {
        margin-left: 10px;
    }
}


/* .content-menu-mobile
----------------------------------------------------------------------------------------*/
.content-menu-mobile-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    background-color: rgba(58, 121, 230, 0.5);
    opacity: 0;
    visibility: hidden;
}
.content-menu-mobile-mask .closer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.content-menu-mobile {
    position: fixed;
    top: 0;
    right: -405px;
    width: 400px;
    max-width: 90vw;
    bottom: 0;
    background-color: #fff;
    color: #7f7f7f;
    box-sizing: border-box;
    border-left: 1px solid #ebebeb;
    z-index: 2;
}

.content-menu-mobile-mask.opened .content-menu-mobile {
    right: 0;
}
.content-menu-mobile-mask.opened {
    visibility: visible;
    opacity: 1;
}

.content-menu-mobile .content {
    position: relative;
    overflow-x: hidden;
    margin: 88px 0 20px;
    height: calc(100vh - 88px);
}

.content-menu-mobile .content ul li {
    padding: 15px 20px;
    border-bottom: 1px solid #ebebeb;
}
.content-menu-mobile .content ul li a {
    display: block;
}

.content-menu-mobile .content ul li a:not(.button) {
    font-size: 15px;
    color: #7f7f7f;
    text-decoration: none;
}

.content-menu-mobile .content ul li a:not(.button):hover, .content-menu-mobile .content ul li.active>a {
    color: #3a79e6;
}

@media all and (min-width: 601px) {
    .main-header-showcase .only-mobile, 
    .content-menu-mobile-mask {
        display: none!important;
    }
}

@media all and (max-width: 600px) {
    .main-header-showcase .menu li.menu-li-link {
        display: none;
    }
}

/* End of file
----------------------------------------------------------------------------------------*/