/* Here an example for your custom css
----------------------------------------------------------------------------------------*/

#root {
  position: relative;
  min-height: 100vh;
}

body {
  -moz-font-feature-settings: "liga" on;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-rendering: optimizeLegibility;
}

/* Fonts
----------------------------------------------------------------------------------------*/

html,
body {
  line-height: 1.2;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

.font-inter-100 {
  font-family: "Inter", sans-serif;
  font-weight: 100;
}

.font-inter-300 {
  font-family: "Inter", sans-serif;
  font-weight: 300;
}

.font-inter-400 {
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

.font-inter-500 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
}

.sensia-table thead tr th,
.report-table thead,
.font-inter-600 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

.font-inter-700 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
}

/* Placeholder
--------------*/

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  /* WebKit browsers */
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #b5b5b5;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #b5b5b5;
  opacity: 1;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #b5b5b5;
  opacity: 1;
}

.placeholder {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #b5b5b5 !important;
}

/* Color
----------------------------------------------------------------------------------------*/

.color-e1e1e1 {
  color: #e1e1e1;
}

.color-b5b5b5 {
  color: #b5b5b5;
}

.color-e04f77 {
  color: #e04f77;
}

.color-2a2a44 {
  color: #2a2a44;
}

.color-ebebeb {
  color: #ebebeb;
}

.color-3a79e6 {
  color: #3a79e6 !important;
}

.color-0e004b {
  color: #0e004b;
}

.color-7f7f7f {
  color: #7f7f7f !important;
}

.color-969696 {
  color: #969696;
}

.color-b4b4b4 {
  color: #b4b4b4;
}

.color-ffc933 {
  color: #ffc933;
}

.color-d2d2d2 {
  color: #d2d2d2;
}

.color-27c986 {
  color: #27c986;
}

.color-01b96e {
  color: #01b96e;
}

.color-564c81 {
  color: #564c81;
}

.color-30beec {
  color: #30beec;
}

.color-171719 {
  color: #171719;
}
.color-2f2f2f {
  color: #2f2f2f;
}

.background-color-fafafa {
  background-color: #fafafa;
}

.background-color-fbede6 {
  background-color: #fbede6;
}
.background-color-2f2f2f {
  background-color: #2f2f2f;
}

/* Central content
----------------------------------------------------------------------------------------*/

.central-content {
  position: relative;
  max-width: 1100px;
  margin: 0 auto;
}

.central-content.no-responsive {
  width: 1100px;
}

@media only screen and (max-width: 1240px) {
  .central-content:not(.no-responsive-margin) {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1130px) {
  .central-content:not(.no-responsive-margin) {
    margin: 0 15px;
  }
}

.central-content-664 {
  position: relative;
  max-width: 664px;
  margin: 0 auto;
}

.central-content-876 {
  position: relative;
  max-width: 876px;
  margin: 0 auto;
}

.central-content-1200 {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.central-content-1200.no-responsive {
  width: 1200px;
}

@media only screen and (max-width: 1240px) {
  .central-content-1200:not(.no-responsive-margin) {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1230px) {
  .central-content-1200:not(.no-responsive-margin) {
    margin: 0 15px;
  }
}

.central-content-1260 {
  position: relative;
  max-width: 1260px;
  margin: 0 auto;
}

.central-content-1260.no-responsive {
  width: 1260px;
}
@media only screen and (max-width: 1290px) {
  .central-content-1260:not(.no-responsive-margin) {
    margin: 0 15px;
  }
}

/* Padding
----------------------------------------------------------------------------------------*/

.line-height-1-71 {
  line-height: 1.71;
}

.line-height-22 {
  line-height: 22px;
}
.line-height-24 {
  line-height: 24px;
}

.line-height-28 {
  line-height: 28px;
}

.line-height-30 {
  line-height: 30px;
}

.line-height-34 {
  line-height: 34px;
}

.line-height-42 {
  line-height: 42px;
}

.line-height-53 {
  line-height: 53px;
}

.line-height-83 {
  line-height: 83px;
}

.line-height-35 {
  line-height: 35px;
}

.padding-left-114 {
  padding-left: 114px;
}

.padding-left-4 {
  padding-left: 4px;
}

.padding-left-6 {
  padding-left: 6px;
}

.padding-top-2 {
  padding-top: 2px;
}

.padding-top-3 {
  padding-top: 3px;
}

.padding-top-4 {
  padding-top: 4px;
}

.padding-top-7 {
  padding-top: 7px;
}

.padding-top-8 {
  padding-top: 8px;
}

.padding-top-9 {
  padding-top: 9px;
}

.padding-top-14 {
  padding-top: 14px;
}

.padding-top-24 {
  padding-top: 24px;
}

.padding-top-32 {
  padding-top: 32px;
}

.padding-top-44 {
  padding-top: 44px;
}

.padding-top-75 {
  padding-top: 75px;
}

.padding-top-100 {
  padding-top: 100px;
}

.padding-top-137 {
  padding-top: 137px;
}

.padding-bottom-1 {
  padding-bottom: 1px;
}

.padding-bottom-2 {
  padding-bottom: 2px;
}

.padding-bottom-3 {
  padding-bottom: 3px;
}

.padding-bottom-4 {
  padding-bottom: 4px;
}

.padding-bottom-8 {
  padding-bottom: 8px;
}

.padding-bottom-12 {
  padding-bottom: 12px;
}

.padding-bottom-13 {
  padding-bottom: 13px;
}

.padding-bottom-16 {
  padding-bottom: 16px;
}

.padding-bottom-17 {
  padding-bottom: 17px;
}

.padding-bottom-24 {
  padding-bottom: 24px;
}

.padding-bottom-26 {
  padding-bottom: 26px;
}

.padding-bottom-27 {
  padding-bottom: 26px;
}

.padding-bottom-28 {
  padding-bottom: 28px;
}

.padding-bottom-36 {
  padding-bottom: 36px;
}

.padding-bottom-47 {
  padding-bottom: 47px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.padding-bottom-100 {
  padding-bottom: 100px;
}

.padding-horizontal-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.padding-vertical-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.padding-left-24 {
  padding-left: 24px;
}

.margin-right-6 {
  margin-right: 6px;
}

.margin-top-75 {
  margin-top: 75px;
}

.font-size-34 {
  font-size: 34px;
}

/* INPUT
----------------------------------------------------------------------------------------*/

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"] {
  border-radius: 6px;
  border-color: #b4b4b4;
  line-height: 40px;
  padding: 0 12px;
  font-size: 15px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="password"]:focus {
  border-color: #3a79e6;
}
input.height-34 {
  line-height: 34px;
}

.max-width-530 {
  width: 100%;
  max-width: 530px;
}

.min-height-150 {
  min-height: 150px;
  box-sizing: border-box;
}

.min-height-210 {
  min-height: 210px;
  box-sizing: border-box;
}

input.width-720 {
  width: 100%;
  max-width: 720px;
}
input.width-200 {
  width: 100%;
  max-width: 200px;
}

textarea {
  border-radius: 6px;
  border: 1px solid #b4b4b4;
  line-height: 1.3;
  padding: 12px;
  font-size: 15px;
}

textarea:focus {
  border-color: #3a79e6;
}

textarea:disabled {
  background-color: #fff;
}

input[type="text"].error,
input[type="email"].error,
input[type="number"].error,
input[type="password"].error,
textarea.error {
  border-color: #e04f77;
}

#react-select-2-input {
  line-height: 34px;
}

.form-element .full-content-radio label,
.full-content-radio label {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 0;
}
.form-element .full-content-radio {
  margin-bottom: 16px;
}

/* Button
----------------------------------------------------------------------------------------*/

.button {
  line-height: 34px;
  border-radius: 17px;
  font-size: 15px;
  text-align: center;
  position: relative;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  outline: 0;
  padding: 0 20px;
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
  background-color: #3a79e6;
  -webkit-transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
  -moz-transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
  -o-transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
  -ms-transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
}

.button.width-170 {
  width: 170px;
}

.button.height-45 {
  height: 45px;
  line-height: 45px;
}

.button.height-48 {
  height: 48px;
  line-height: 48px;
  border-radius: 24px;
  font-weight: 600;
  font-size: 20px;
}

.button:disabled {
  opacity: 0.5;
  cursor: default;
}

.loading-form .button,
.loading-form .button:disabled {
  opacity: 1;
  color: transparent !important;
  overflow: hidden;
}

.button:not(:disabled):hover {
  background-color: #0075ff;
}

.link-multi-state {
  position: relative;
  text-decoration: none;
  cursor: pointer;
}

.link-multi-state:hover .initial,
.link-multi-state .on-hover {
  display: none;
}

.link-multi-state:hover .on-hover {
  display: block;
}

.link-blue {
  text-decoration: none;
  color: #3a79e6;
  cursor: pointer;
}

.link-blue:hover {
  text-decoration: none;
  color: #0075ff;
}

.link-grey {
  text-decoration: none;
  color: #7f7f7f;
  cursor: pointer;
}

.link-grey:hover {
  text-decoration: none;
  color: #535353;
}

.button.light-blue {
  background-color: #e2ebfd;
  color: #3a79e6;
}

.button.light-blue:not(:disabled):hover {
  background-color: #cde3fe;
}

.button.backgroundless {
  background-color: transparent !important;
  color: #3a79e6;
  font-size: 17px;
  font-weight: 500;
}

.button.backgroundless.font-weight-600 {
  font-weight: 600;
}

.button.backgroundless:not(:disabled):hover {
  color: #0075ff;
}

.button.backgroundless.pink {
  color: #e04f77;
}

.button.backgroundless.grey {
  color: #969696;
}

.button.backgroundless.grey:hover {
  color: #747474;
}

.button.backgroundless:disabled {
  color: #969696 !important;
}

.min-height-450 {
  min-height: 450px;
}

.react-cooked-bread__container {
  bottom: auto !important;
  top: 60px !important;
}

/* utils
----------------------------------------------------------------------------------------*/

.shape-divider-white {
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  height: 85px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../images/courbe-white-masque.svg");
  z-index: 2;
}

.shape-divider-white2 {
  position: absolute;
  padding-bottom: 30px;
  left: 0;
  width: 100%;
  height: 85px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../images/courbe-white-masque.svg");
  z-index: 2;
}

.content-3-columns-centered
  .content-column
  .column-percent-33:not(.text-align-center):nth-child(3n + 3) {
  text-align: right;
}
.content-3-columns-centered .content-column .column-percent-34 {
  text-align: center;
}
.content-3-columns-centered .content-column .solution-item,
.content-3-columns-centered .content-column .accelerate-project-item {
  display: inline-block;
}

.content-column .team-box:last-child {
  text-align: right;
}

@media all and (max-width: 1040px) {
  .responsive-1040-padding-top-0 {
    padding-top: 0 !important;
  }
  .content-3-columns-centered > .title {
    text-align: center;
  }
  .content-3-columns-centered .content-column .column-percent-33,
  .content-3-columns-centered .content-column .column-percent-34,
  .content-3-columns-centered
    .content-column
    .column-percent-33:nth-child(3n + 3) {
    width: auto;
    float: none;
    text-align: center !important;
    margin-bottom: 20px;
  }
  .content-3-columns-centered .solution-item,
  .content-3-columns-centered .accelerate-project-item {
    max-width: 560px;
  }
  .content-3-columns-centered .accelerate-project-item {
    margin-bottom: 20px;
  }

  .content-teams .team-title-big {
    text-align: center;
  }
  .content-keep-calm .content-column,
  .content-teams .content-column {
    text-align: center;
  }

  .content-teams .content-column .column-percent-50 {
    display: inline-block;
  }
  .content-teams .content-column .column-percent-50,
  .content-keep-calm .content-column .column-percent-50 {
    width: auto;
    float: none;
    margin-bottom: 60px;
    display: inline-block;
  }
  .content-keep-calm .content-column .column-percent-50:last-child,
  .content-teams .content-column .column-percent-50:last-child {
    margin-bottom: 0;
  }
  .keep-calm-box {
    padding-left: 0 !important;
    text-align: left;
  }
}

/* homepage
----------------------------------------------------------------------------------------*/

/* .content-display-main-character
----------------------------------------------------------------------------------------*/

.content-display-main-character {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.5;
  color: #000000;
}

.content-display-main-character span {
  font-weight: 500;
  color: #3a79e6;
}

.content-display-main-character span.negative,
.content-display-main-character span.color-score-negative,
.color-score-negative {
  color: #e04f77;
}

.content-display-main-character,
.color-score-neutral {
  font-weight: normal;
  color: #000000;
}

.span.neutral,
span.color-score-neutral {
  font-weight: 600;
  color: #867fa5;
}

div.color-score-neutral {
  font-weight: 600;
  color: #867fa5;
}

.content-display-main-character span.positive,
.content-display-main-character span.color-score-positive,
.color-score-positive {
  color: #27c986;
}

.border-score {
  position: absolute;
  width: 2px;
  height: 100%;
  border-radius: 1px;
  background-color: #d2d2d2;
  top: 0;
  left: 0;
}

.border-score .triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #d8d8d8;
  top: 50%;
  margin-top: -10px;
  left: 2px;
}

@media screen and (max-width: 768px) {
  .border-score .triangle {
    display: none;
  }
}

.content-project-stats {
  width: 85%;
  border-radius: 8px;
  border: solid 1px #d2d2d2;
  margin-left: 20px;
  height: 99%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-left: 55px;
}

.content-project-stats-constainer {
  display: flex;
  align-items: flex-start;
  height: 202px;
}

@media screen and (max-width: 920px) {
  .content-project-stats {
    margin-left: 0;
    padding-left: 100px;
  }
}

@media screen and (max-width: 720px) {
  .content-project-stats {
    width: 85%;
  }
}

@media screen and (max-width: 480px) {
  .content-project-stats {
    padding-left: 60px;
  }
}

@media screen and (max-width: 350px) {
  .content-project-stats {
    width: 85%;
    padding-left: 20px;
  }

  .content-project-stats .content-project-emotion {
    margin-left: 0 !important;
    float: right;
  }
}

.content-project-kpi {
  width: 40%;
  margin: 10px;
}

.content-project-kpi-50 {
  width: 50%;
  margin: 10px 0;
}

.content-project-kpi-45 {
  width: 45%;
  margin: 10px 0;
}

.content-project-kpi-30 {
  width: 30%;
  margin: 10px 0;
}

.content-project-score {
  position: relative;
  width: 100px;
  display: inline-block;
  vertical-align: middle;
}

.content-project-score.workspace {
  width: 110px;
}

.content-project-emotion {
  position: relative;
  margin-left: 90px;
  width: 130px;
  display: inline-block;
  vertical-align: middle;
}

.content-project-emotion.workspace {
  width: 140px;
  margin-left: 70px;
}

@media screen and (min-width: 921px) {
  .only-responsive-920 {
    display: none !important;
  }
}

@media screen and (max-width: 920px) {
  .no-responsive-920 {
    display: none !important;
  }

  .responsive-920-padding-top-20 {
    padding-top: 20px !important;
  }

  .responsive-920-padding-bottom-20 {
    padding-bottom: 20px !important;
  }

  .responsive-920-padding-right-0 {
    padding-right: 0 !important;
  }

  .responsive-920-margin-top-0 {
    margin-top: 0 !important;
  }

  .responsive-920-margin-bottom-0 {
    margin-bottom: 0 !important;
  }

  .responsive-920-margin-vertical-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .responsive-920-position-normal {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }

  .only-responsive-920 .border-score {
    position: relative;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background-color: #d2d2d2;
    margin-top: 8px;
    margin-bottom: 30px;
  }

  .only-responsive-920 .border-score .triangle {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #d8d8d8 transparent transparent transparent;
    top: 2px;
    margin-top: 0;
    left: 50%;
    margin-left: -10px;
    display: block !important;
  }

  .project-detail-content-top-column .column-percent-40,
  .project-refine-content-top-column .column-percent-25 {
    display: none;
  }

  .project-detail-content-top-column .column-percent-60,
  .project-refine-content-top-column .column-percent-75 {
    width: 100%;
  }

  .project-detail-content-top-column .column-percent-60 > .padding-right-30,
  .project-refine-content-top-column .column-percent-75 > .padding-right-30 {
    padding-right: 0;
  }

  .media-list-item .content-left {
    margin-right: 50px !important;
  }
}

@media screen and (max-width: 365px) {
  .only-responsive-920 .content-project-emotion {
    margin-left: 60px;
  }
}

.content-project-score .score {
  font-weight: 600;
  line-height: normal;
  color: #d2d2d2;
}

.content-project-score .score-description {
  line-height: 1.33;
  letter-spacing: -0.5px;
  padding-top: 3px;
}

.c-tooltip {
  display: inline;
}

.content-title-project > .position-relative {
  margin-right: 400px;
}

@media all and (max-width: 768px) {
  .content-title-project > .position-relative {
    margin-right: 0;
  }

  .content-title-project > .position-absolute-top-right {
    position: relative;
    text-align: right;
  }
}

@media all and (max-width: 430px) {
  .content-title-project > .position-relative {
    margin-right: 0;
  }

  .content-title-project > .position-absolute-top-right {
    position: relative;
    text-align: right;
  }
}

@media all and (max-width: 430px) {
  .content-title-project > .position-absolute-top-right > * {
    margin-bottom: 5px;
  }
}

/* .content-text-view-more
----------------------------------------------------------------------------------------*/

.line-separator-ebebeb {
  position: relative;
  height: 1px;
  background: #ebebeb;
  margin: 36px 0;
}

.line-separator-transparent {
  position: relative;
  height: 1px;
  margin: 36px 0;
}

.content-text-view-more {
  position: relative;
  overflow: hidden;
  max-height: 211px;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.content-text-view-more.opened {
  max-height: 2000px;
  transition: max-height 1s ease-in-out;
}

/* .settings-content
----------------------------------------------------------------------------------------*/

.settings-button-go-back {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 22px;
}

.settings-button-go-back:hover {
  opacity: 0.8;
}

.settings-button-go-back span {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #7f7f7f;
}

.settings-button-go-back span.icon {
  width: 6px;
  height: 12px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("../images/settings/icon-back.svg");
  margin-right: 8px;
}

.settings-content {
  position: relative;
  display: flex;
}

.settings-content .left {
  flex: calc(100% - 720px);
  position: -webkit-sticky;
  position: sticky;
  top: 90px;
  align-self: flex-start;
}

.settings-content .right {
  flex: 720px;
}

@media all and (min-width: 1001px) {
  .responsive-show-1000 {
    display: none !important;
  }
}

@media all and (max-width: 1000px) {
  .settings-content {
    display: block;
  }

  .settings-content .left {
    display: none;
    flex: none;
  }

  .settings-content.responsive .left {
    position: relative;
    display: block;
    top: 0;
  }

  .settings-content .right {
    flex: none;
  }

  .responsive-hide-1000 {
    display: none !important;
  }
}

.content-settings-menu {
  position: relative;
  width: 245px;
}

.content-settings-menu a {
  position: relative;
  display: block;
  height: 48px;
  padding: 0 16px;
  border-radius: 6px;
  margin-bottom: 8px;
  background-color: transparent;
  font-weight: 500;
  font-size: 15px;
  line-height: 48px;
  color: #7f7f7f;
  text-decoration: none;
  box-sizing: border-box;
}

.content-settings-menu a:hover,
.content-settings-menu a.active {
  background-color: #fafafa;
  color: #000000;
}

.content-settings-menu a.active {
  font-weight: 600;
}

.content-settings-save-bar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background-color: #f2f2f2;
  visibility: hidden;
  opacity: 0;
}

.content-settings-save-bar.visible {
  visibility: visible;
  opacity: 1;
}

@media all and (max-width: 400px) {
  .content-settings-save-bar .changes-libelle {
    position: relative;
    display: block;
    padding-top: 10px;
  }
}

.content-hoverable-billing {
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  padding: 12px 15px;
  border-radius: 6px;
  background-color: #fff;
}

.content-hoverable-billing:hover {
  background-color: #fafafa;
}

.content-settings-tabs {
  position: relative;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 24px;
}

.content-settings-tabs .tab {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border-bottom: 4px solid transparent;
  font-size: 16px;
  box-sizing: border-box;
  padding: 5px 8px;
  margin-right: 5px;
  cursor: pointer;
  height: 36px;
  line-height: 24px;
}

.content-settings-tabs .tab:hover,
.content-settings-tabs .tab.active {
  border-color: #3a79e6;
  font-weight: 700;
}

@media only screen and (max-width: 760px) {
  .hide-760 {
    display: none !important;
  }
}

.clear-both {
  clear: both;
}

.best-keyword-line {
  position: relative;
  line-height: 34px;
}

.best-keyword-line .child {
  display: block;
  line-height: 18px;
}

.best-keyword-line .child > span {
  line-height: 1.2;
}

.best-keyword-line .best-keyword-right-arrow {
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 12px;
  background-image: url("../images/right-arrow.svg");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: 5px;
}

@media all and (max-width: 920px) {
  .best-keyword-line .child > span:first-child {
    max-width: calc(100% - 20px);
  }

  .best-keyword-line .child > span {
    display: inline-block;
    vertical-align: middle;
  }
}

/* End of file
----------------------------------------------------------------------------------------*/
