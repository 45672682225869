.c-tooltip__trigger {
    display: inline;
}

.c-ellipsis-tooltip .c-tooltip__trigger {
    /* Doing this to avoid a too large overlapping content
    pushing the tooltip too far away from the visible text */
    max-width: 101%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.popover {
    z-index: 3;
}

.fade.show.tooltip {
    z-index: 4;
}

.base-tooltip {
    margin-top: 3px;
    width: 300px;
    padding: 16px;
    border-radius: 6px;
    border: solid 1px #b4b4b4;
    background-color: #fff;
    box-sizing: border-box;
}

.base-tooltip.max-width-512 {
    width: auto;
    max-width: 512px;
}

.tooltip-separator {
    position: relative;
    height: 1px;
    margin: 10px -17px;
    background-color: #ebebeb;
}

.tooltip-adjust-score-text {
    position: absolute;
    margin-top: 14px;
    margin-bottom: 17px;
    font-size: 16px;
    font-weight: 500;
    color: #000;
}

.tooltip-adjust-score-button {
    position: absolute;
    margin-top: 14px;
    margin-bottom: 17px;
    right: 0px;
    text-align: right;
    font-size: 16;
    font-weight: 500;
    color: #000;
    width: 50%;
}

.content-tooltip-help-score .width-100 {
    width: 100px;
}

.content-tooltip-help-score .width-75 {
    width: 75px;
}

.content-tooltip-help-score .width-130 {
    width: 130px;
}

.content-tooltip-help-score .color-bar {
    position: relative;
    height: 4px;
    font-size: 0;
    margin: 4px 0 1px;
}

.content-tooltip-help-score .color-bar .bar {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    height: 4px;
    margin: 0;
    border-radius: 2px;
    background-color: #e04f77;
}

.content-tooltip-help-score .color-bar .bar:nth-child(1) {
    width: 86px;
    background-color: #e04f77;
}

.content-tooltip-help-score .color-bar .bar:nth-child(2) {
    width: 70px;
    margin: 0 2px;
    background-color: #9f99b7;
}

.content-tooltip-help-score .color-bar .bar:nth-child(3) {
    width: 86px;
    background-color: #27c986;
}

#chartjs-graph-emotion-tooltip .base-tooltip {
    width: 270px;
    margin-top: 6px;
}

.tooltip-content .line-emotion {
    font-size: 0;
    margin: 0 0 14px 0;
}

.tooltip-content .line-emotion:last-child {
    margin-bottom: 0;
}

.tooltip-content .line-emotion .content-bar,
.tooltip-content .line-emotion .content-percent,
.tooltip-content .line-emotion .content-libelle {
    position: relative;
    display: inline-block;
    vertical-align: bottom;
}

.tooltip-content .line-emotion .content-bar {
    width: 76px;
    height: 5px;
    border-radius: 2.5px;
    overflow: hidden;
    top: -3px;
}

.tooltip-content .line-emotion .content-bar .bar {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    border-radius: 2.5px;
    background-color: #9f99b7;
}

.tooltip-content .line-emotion .content-percent {
    width: 30px;
    margin: 0 5px;
}

.tooltip-content .line-emotion .content-libelle {
    width: 115px;
    margin-left: 5px;
}

.mini-round-icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.mini-round-icon.following {
    background-image: url('../../res/images/pic-follow.svg');
}

.mini-round-icon.unfollow {
    background-image: url('../../res/images/pic-unfollow.svg');
}

.positive-percent {
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-weight: 500;
    color: #27c986;
}

.negative-percent-keyword-tooltip {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #e04f77;
    margin-top: 8px;
}

.positive-percent-keyword-tooltip {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #27c986;
    margin-top: 8px;
}

.negative-percent {
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-weight: 500;
    color: #e04f77;
}

.flex-tooltip {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}