
/* Loading view
----------------------------------------------------------------------------------------*/

.content-loading-view {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 18;
}

.content-loading-view .website-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -15px;
    margin-left: -15px;
}