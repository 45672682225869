.switch-price-container {
    height: 46px;
    padding: 4px;
    border-radius: 23px;
    background-color: #e2ebfd;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    text-align: left;
  }
  
  .switch-price-container .price-type {
    display: inline-block;
    padding: 10px 18px;
    font-size: 15px;
    font-weight: 600;
    color: #3a79e6;
    cursor: pointer;
    user-select: none;
  }
  
  .switch-price-container .price-type:before {
    position: absolute;
    left: 4px;
    content: attr(data-text);
    top: 4px;
    border-radius: 20px;
    background-color: #3a79e6;
    transition: .3s;
    padding: 10px 18px;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    opacity: 0;
    user-select: none;
  }

.switch-price-container:not(.second) .price-type:first-child:before,
.switch-price-container.second .price-type:last-child:before {
  opacity: 1;
}
.switch-price-container.second .price-type:before {
  left: calc(100% - 4px);
  transform: translateX(-100%);
}

