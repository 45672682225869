/* Main Footer
----------------------------------------------------------------------------------------*/


.footer-showcase {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 60px 0;
    background-color: #f78f64;
    color: #fff;
}
.fake.footer-showcase {
    position: relative;
    opacity: 0;
    visibility: hidden;
}

.footer-showcase .content-logo-socials .content-socials .social {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
    width: 34px;
    height: 34px;
    background-size: 34px 34px;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    text-decoration: none;
    transition: opacity 0.3s ease;
}

.footer-showcase .content-socials .social span {
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 34px;
    background-size: 34px 34px;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    text-decoration: none;
    transition: opacity 0.3s ease;
    opacity: 0;
    background-color: rgba(0,0,0,0.1);
    border-radius: 17px;
}

.footer-showcase .content-logo-socials .content-socials .social:hover span {
    opacity: 1;
}

.footer-showcase .content-logo-socials .content-socials .social.linkedin {
    background-image: url("../../res/images//social/linkedin.svg");
}

.footer-showcase .content-logo-socials .content-socials .social.twitter {
    background-image: url("../../res/images//social/twitter.svg");
}

.footer-showcase .content-logo-socials .content-socials .social.facebook {
    background-image: url("../../res/images//social/facebook.svg");
}

.footer-showcase .content-logo-socials .content-socials .social.instagram {
    background-image: url("../../res/images//social/instagram.svg");
}


.footer-showcase .content-menu-and-copyright {
    color: #fafafa;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.54;
    padding-top: 6px;
}

.footer-showcase .corellis-copyright {
    display: inline-block;
}

.footer-showcase .content-menu-and-copyright a {
    color: #fafafa;
}

.footer-showcase .content-menu-and-copyright ul {
    list-style-type: none;
    display: inline-block;
}

.footer-showcase .content-menu-and-copyright ul li {
    display: inline-block;
    margin-right: 15px;
}

.footer-showcase .content-menu-and-copyright ul li a {
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.2;
}

.footer-showcase .content-menu-and-copyright ul li a:hover {
    text-decoration: underline;
}

.flex-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media  all and (max-width: 700px) {
    .footer-showcase .content-menu-and-copyright ul {
        display: block;
        padding-top: 10px;
        margin-left: 0;
    }
    .footer-showcase .content-menu-and-copyright ul li {
        display: block;
        margin: 0 0 5px 0;
    }
    .flex-footer {
        flex-direction: column;
        justify-content: center;
    }
    .content-menu-and-copyright {
        padding-bottom: 10px;
    }
    .footer-showcase .content-logo-socials .content-socials .social {
        margin: 0;
    }
}

@media  all and (max-width: 450px) {
    .footer-showcase {
        padding: 40px 0;
    }
    .footer-showcase .content-logo-socials .content-socials {
        position: relative;
        display: block;
        padding-top: 10px;
    }
    .footer-showcase .content-logo-socials .content-socials .social {
        margin-left: 0;
        margin-right: 10px;
        margin-bottom: 5px;
    }
    .footer-showcase .content-menu-and-copyright {
        padding-top: 20px;
    }
}

/* End of file
----------------------------------------------------------------------------------------*/