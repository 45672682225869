#scores {
  width: 100%;
  height: 60px;
  display: flex;
  gap: 26px;
  flex-wrap: wrap;
}
#scores.medium {
  height: 47px;
  -moz-column-gap: 14px;
       column-gap: 14px;
}
#scores .separator {
  width: 1px;
  height: 100%;
  background-color: #eee;
}
#scores .number-box,
#scores .feeling-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 4px;
  position: relative;
}
#scores .number-box p,
#scores .feeling-box p {
  font-size: 10px;
  font-weight: 500;
  color: #9e9e9e;
  text-transform: uppercase;
}
#scores .number-box h3,
#scores .feeling-box h3 {
  height: 30px;
  font-size: 24px;
  font-weight: 500;
  color: #000;
  display: flex;
  align-items: flex-end;
  -moz-column-gap: 5px;
       column-gap: 5px;
}
#scores .number-box h3.medium,
#scores .feeling-box h3.medium {
  font-size: 20px;
}
#scores .number-box h3.positive,
#scores .feeling-box h3.positive {
  color: #27c986;
}
#scores .number-box h3.negative,
#scores .feeling-box h3.negative {
  color: #e04f77;
}
#scores .number-box h3 .progress-bar,
#scores .feeling-box h3 .progress-bar {
  height: 5px;
  width: 76px;
  background-color: #eee;
  position: relative;
  bottom: 6px;
  border-radius: 100px;
  overflow: hidden;
}
#scores .number-box h3 .progress-bar.medium,
#scores .feeling-box h3 .progress-bar.medium {
  width: 55px;
}
#scores .number-box h3 .progress-bar .progress-bar-content,
#scores .feeling-box h3 .progress-bar .progress-bar-content {
  height: 100%;
  background-color: #000;
}
#scores .feeling-box.feeling {
  min-width: 130px;
}
#scores .feeling-box div {
  display: flex !important;
  align-items: flex-end !important;
  -moz-column-gap: 4px;
       column-gap: 4px;
}
#scores .feeling-box div #color-dot {
  position: relative;
  bottom: 7px;
}
#scores .feeling-box div h3 {
  font-size: 15px;
  font-weight: normal;
  color: #757575;
  display: flex;
  align-items: flex-end;
  position: relative;
  bottom: 2px;
}
#scores .feeling-box div h3.medium {
  font-size: 15px;
}
#scores .feeling-box .c-tooltip {
  height: 30px;
  display: flex;
  align-items: center;
}
#scores .feeling-box .c-tooltip__trigger {
  position: relative;
  top: 3px;
}
#scores #source-tooltip {
  background-color: transparent;
  position: absolute;
  top: 50px;
  z-index: 17;
  left: -120px;
  display: none;
}
#scores #source-tooltip #cachetop {
  position: absolute;
  height: 16px;
  width: calc(100% - 8px);
  top: 21px;
  left: 2px;
  background-color: white;
  z-index: 17;
  border-radius: 6px;
}
#scores #source-tooltip #cachebottom {
  position: absolute;
  height: 16px;
  width: calc(100% - 8px);
  bottom: 0;
  left: 2px;
  background-color: white;
  z-index: 17;
  border-radius: 6px;
}
#scores #source-tooltip .content {
  width: 270px;
  max-height: 315px;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  border: solid 1px #eee;
  background-color: #fff;
  overflow-y: scroll;
  margin-top: 20px;
  position: relative;
}
#scores #source-tooltip .content + p {
  font-size: 10px;
  font-weight: 500;
  color: #9e9e9e;
}
#scores #source-tooltip .content .box:not(:nth-child(1)) {
  margin-top: 16px;
}
#scores #source-tooltip .content .box .item {
  display: flex;
  align-items: center;
  -moz-column-gap: 6px;
       column-gap: 6px;
  margin-top: 8px;
}
#scores #source-tooltip .content .box .item-text {
  width: 100%;
  overflow-x: hidden;
}
#scores #source-tooltip .content .box .item-text .title {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
#scores #source-tooltip .content .box .item-text p {
  text-transform: none;
}
#scores #relevant-tooltip {
  background-color: transparent;
  position: absolute;
  top: 50px;
  z-index: 17;
  left: -120px;
  display: none;
}
#scores #relevant-tooltip .content {
  width: 270px;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  border: solid 1px #eee;
  background-color: #fff;
  overflow-y: scroll;
  margin-top: 20px;
  position: relative;
}
#scores #relevant-tooltip .content + p {
  font-size: 10px;
  font-weight: 500;
  color: #9e9e9e;
}
#scores #relevant-tooltip .content .top-infos {
  display: flex;
  justify-content: space-between;
}
#scores #relevant-tooltip .content .top-infos .relevant-title {
  display: flex;
  align-items: flex-end;
  -moz-column-gap: 4px;
       column-gap: 4px;
}
#scores #relevant-tooltip .content .top-infos .relevant-title p {
  position: relative;
  bottom: 4px;
  text-transform: none;
  color: #757575;
  font-weight: normal;
}
#scores #relevant-tooltip .content .top-infos h3:last-child {
  font-size: 15px;
  position: relative;
  bottom: 4px;
}
#scores #relevant-tooltip .content .progress-bar {
  height: 5px;
  width: 100%;
  background-color: #eee;
  border-radius: 100px;
  overflow: hidden;
}
#scores #relevant-tooltip .content .progress-bar .progress-bar-content {
  height: 100%;
  background-color: #000;
}
#scores #relevant-tooltip .content p {
  text-transform: none;
  margin-top: 4px;
  font-size: 12px;
}
#scores #relevant-tooltip .content .last-review {
  display: flex;
  align-items: center;
  -moz-column-gap: 6px;
       column-gap: 6px;
  margin-top: 14px;
}
#scores #sources:hover #source-tooltip {
  display: block;
}
#scores #reviews:hover #relevant-tooltip {
  display: block;
}
#scores .content-rounded-emotion {
  display: flex;
  align-items: center;
  position: relative;
  bottom: 7px;
}
#scores .content-rounded-emotion div {
  font-size: 15px;
}/*# sourceMappingURL=Score.css.map */