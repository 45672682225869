
/* Errors
----------------------------------------------------------------------------------------*/

.content-error-to-center {
    position: relative;
    display: table;
    width: 100%;
    height: 100%;
    padding: 90px 0 240px;
    box-sizing: border-box;
    min-height: 100vh;
  }
  
  .content-error-to-center .centered {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }