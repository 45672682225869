.content-rounded-emotion .round-emotion-color {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #b6b2c9;
    margin-right: 6px;
}

.blured-emotion {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 130px;
    height: 21px;
    background-size: auto 32px;
    background-repeat: no-repeat;
    background-position: left center;
}