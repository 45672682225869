/* burger-menu-icon
----------------------------------------------------------------------------------------*/
.burger-menu-icon {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 0;
  }
  .burger-menu-icon .line {
    fill: none;
    stroke: #000;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .burger-menu-icon .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .burger-menu-icon .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
  }
  .burger-menu-icon .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .opened .burger-menu-icon .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
  .opened .burger-menu-icon  .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
  }
  .opened .burger-menu-icon  .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
  

/* End of file
----------------------------------------------------------------------------------------*/